<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.order.list')" class="px-5 py-3">
    <!--    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('order.add')">-->
    <!--    </base-add-button>-->
    <div class="mt-2">{{ $t('order.viewTotal') }}: {{ meta.total }}</div>
    <div class="mt-2" v-if="totalPaidAmount">
      {{ $t('order.totalPaid') }}: {{ currencyFormat(totalPaidAmount) }}
    </div>
    <div class="d-flex align-items-center">
      <base-dropdown-filter
        :items="statusFilters"
        @changeFilter="changeStatusFilter"
        style="flex: 0 0 65%; max-width: 65%"
        label="Filter Status"
      >
      </base-dropdown-filter>
      <base-date-filter
        @changeFilter="changeDateFilter"
        placeholder="Periode Pembelian"
        style="flex: 0 0 35%; max-width: 35%"
      >
      </base-date-filter>
    </div>
    <div class="d-flex" style="margin-top: 46px">
      <base-dropdown-filter
        :items="typeFilters"
        @changeFilter="changeTypeFilter"
        style="flex: 0 0 75%; max-width: 75%"
        label="Tipe Produk"
      >
      </base-dropdown-filter>
      <base-dropdown-filter
        :items="salesFilters"
        @changeFilter="changeSalesFilter"
        style="flex: 0 0 25%; max-width: 25%"
        label="Siapa Salesnya"
      >
      </base-dropdown-filter>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="text-center primary--text" v-if="isRefundAllowed !== null">
            {{ $t('general.actions') }}
          </th>
          <th class="primary--text">
            {{ $t('order.orderNumber') }}
          </th>
          <th class="primary--text">
            {{ $t('order.productName') }}
          </th>
          <th class="primary--text">
            {{ $t('order.salesName') }}
          </th>
          <th class="primary--text">
            {{ $t('order.agentName') }}
          </th>
          <th class="primary--text">
            {{ $t('order.agentEmail') }}
          </th>
          <th class="primary--text">
            {{ $t('order.agentPhone') }}
          </th>
          <th class="primary--text">
            {{ $t('order.status') }}
          </th>
          <th class="primary--text">
            {{ $t('order.paidAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('order.paymentVia') }}
          </th>
          <th class="primary--text">
            {{ $t('order.orderDate') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`order-${index}`">
          <td>{{ index + 1 }}</td>
          <td v-if="isRefundAllowed !== null">
            <div class="d-flex justify-center align-center">
              <v-btn color="primary" @click="initiateRefund(item)">
                {{ $t('order.refund') }}
              </v-btn>
            </div>
          </td>
          <td>{{ item.order_number }}</td>
          <td>{{ item.product_name }}</td>
          <td>{{ item.sales_name }}</td>
          <td>{{ item.agent_name }}</td>
          <td>{{ item.agent_email }}</td>
          <td>{{ item.agent_phone }}</td>
          <td>{{ item.status }}</td>
          <td>{{ currencyFormat(item.paid_amount) }}</td>
          <td>{{ item.payment_via }}</td>
          <td>
            {{ item.created_at ? format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.order.basePath,
      statusFilters: (state) => state.order.statusFilters,
      typeFilters: (state) => state.order.typeFilters,
      salesFilters: (state) => state.order.salesFilters,
      totalPaidAmount: (state) => state.order.totalPaidAmount,
      isRefundAllowed: (state) => state.order.isRefundAllowed,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeDateFilter(val) {
      this.$emit('changeDateFilter', val);
    },
    changeStatusFilter(val) {
      this.$emit('changeStatusFilter', val);
    },
    changeTypeFilter(val) {
      this.$emit('changeTypeFilter', val);
    },
    changeSalesFilter(val) {
      this.$emit('changeSalesFilter', val);
    },
    async initiateRefund(item) {
      if (item && item.uuid) {
        if (this.isRefundAllowed === 'INITIATOR') {
          await this.$router.push(`/refund-management-initiator/refunds/add/${item.uuid}`);
        } else if (this.isRefundAllowed === 'MANAGER') {
          await this.$router.push(`/refund-management-manager/refunds/add/${item.uuid}`);
        }
      }
    },
  },
};
</script>
